/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PaginationProps } from "antd"
import { ConfigProvider, Empty, Pagination, Select, Spin } from "antd"
import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { CSVLink } from "react-csv"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import {
  useGetTeamFiltersLazyQuery,
} from "../../../../../../../graphql"
import { DownloadIcon, EditDots, PlusIcon, SearchIcon } from "../../../../../../../icons"
import { removeSelectDuplicates } from "../../../../../../../utils"
import "./index.less"
import { useAuth } from "../../../../../../../components/auth"

const List: FC<RoleListInterface> = ({
  list,
  page,
  loading,
  setPage,
  filters,
  pageSize,
  getStatus,
  setFilters,
  totalItems,
  setLoading,
  setPageSize,
  exportLoading,
  listForExport,
  downloadBtnRef,
  createExportList,
  createQueryFilters,
}) => {
  const { permissions } = useAuth()
  const blockPermissions = permissions?.firstMenu?.find(item => item.name == "Team")?.blocks?.find((block: any) => block.name == "Salon")

  const navigate = useNavigate()

  const [getTeamFilters] = useGetTeamFiltersLazyQuery()

  const [names, setNames] = useState<SelectItems>()
  const [emails, setEmails] = useState<SelectItems>()
  const [salesManagers, setSalesManagers] = useState<SelectItems>()
  const [distributors, setDistributors] = useState<SelectItems>()

  const refFiltersDataSet = useRef<null | any[] | undefined>(null)

  const onChangePage: PaginationProps["onChange"] = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const onChangePageSize: PaginationProps["onShowSizeChange"] = (_, pageSize: number) => {
    setPageSize(pageSize)
  }

  function filterData(dataArray: any[], filters: any) {

    const keyMapping: { [key: string]: string } = {
      selectedName: "name",
      selectedEmail: "email",
      selectedSM: "smName",
      selectedInsiderStatus: "insiderPermission",
      selectedDistributor: "dName",
      selectedRegion: "region",
      selectedStatus: String(filters.selectedStatus).toLowerCase()
    };

    const result = dataArray.filter((item) =>
      Object.keys(filters).every((filterKey) => {
        const dataKey = keyMapping[filterKey] || filterKey;
        const filterValue = filters[filterKey];
        if (filterValue === "All") return true;
        if (filterKey === 'selectedInsiderStatus') {
          if (filterValue === 'Allowed') {
            return +item[dataKey] === 1
          } else {
            return +item[dataKey] === 0
          }
        }

        return item?.[dataKey] && typeof item[dataKey] === 'string' ? item?.[dataKey]?.includes(filterValue) : +item?.[dataKey] === 1;
      })
    );

    return result
  }



  const getFilters = async () => {
    let initFilters = refFiltersDataSet.current
    if (!initFilters) {
      const newFilters = await getTeamFilters({ variables: { input: { type: 'salon' } }, fetchPolicy: 'no-cache' })
      refFiltersDataSet.current = newFilters.data?.getTeamFilters?.filters;
      initFilters = refFiltersDataSet.current
    }
    if (initFilters) {
      const result = filterData(initFilters, filters)
      setNames(removeSelectDuplicates(result.map((f) => ({
        value: f?.name || '',
        label: f?.name || '',
        key: f?.name || '',
      })).filter((item: any) => item.value)!));
      setEmails(removeSelectDuplicates(result.map((f) => ({
        value: f?.email || '',
        label: f?.email || '',
        key: f?.email || '',
      })).filter((item: any) => item.value)!))
      setDistributors(removeSelectDuplicates(result.map((f) => ({
        value: f?.dName || '',
        label: f?.dName || '',
        key: f?.dName || '',
      })).filter((item: any) => item.value))!);
      setSalesManagers(removeSelectDuplicates(result.map((f) => ({
        value: f?.smName || '',
        label: f?.smName || '',
        key: f?.smName || '',
      })).filter((item: any) => item.value))!);
    }
  };

  useEffect(() => {
    getFilters();
  }, [filters]);

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const handleFilter = (data: string, type: string) => {
    const handleFilterChange = (valueType: string) => setFilters({
      ...filters,
      [valueType]: data,
    })

    switch (type) {
      case "name": handleFilterChange("selectedName")
        break
      case "email": handleFilterChange("selectedEmail")
        break
      case "sales-manager": handleFilterChange("selectedSM")
        break
      case "distributor": handleFilterChange("selectedDistributor")
        break
    }
  }

  const createSelectElement = (
    type: string,
    name: string,
    loading: boolean,
    options: SelectItems
  ) => (
    <div
      className={`${type} ${((type == "name" && filters?.selectedName !== "All") ||
        (type == "email" && filters?.selectedEmail !== "All") ||
        (type == "sales-manager" && filters?.salesManager !== "All") ||
        (type == "distributor" && filters?.distributor !== "All")
      ) &&
        "active"
        }`}
      children={
        <Select
          showSearch
          value={name}
          options={options}
          optionFilterProp='children'
          defaultActiveFirstOption={true}
          filterOption={filteredOption as any}
          onChange={data => handleFilter(data, type)}
          notFoundContent={
            loading || !options ? (
              <div className={"no-data"} children={<Spin />} />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
            )
          }
          suffixIcon={<SearchIcon />}
        />
      }
    />
  )

  const handleCreateNewClick = () => navigate("/account/team/salon/new-salon")

  return (
    <SwitchTransition mode={"out-in"}>
      <CSSTransition
        timeout={250}
        appear={true}
        unmountOnExit
        classNames={"fade"}
        key={JSON.stringify(filters) + String(page) + String(pageSize)}
      >
        <div className={"main-list-wrapper salons-list"}>
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  colorPrimary: "black",
                  colorPrimaryBorder: "black",
                  colorPrimaryHover: "black",
                  controlOutline: "#fff",
                },
                Select: {
                  colorText: "#677685",
                  colorPrimaryBorder: "#fff",
                  colorPrimaryHover: "#fff",
                  controlOutline: "#fff",
                  colorBgContainerDisabled: "#fff",
                },
              },
            }}
          >
            <div className={"list-wrapper"}>
              <div className={"items top"}>
                {createSelectElement("name", "NAME", loading, names!)}
                {createSelectElement("email", "EMAIL", loading, emails!)}
                {createSelectElement("sales-manager", "SALES MANAGER", loading, salesManagers!)}
                {createSelectElement("distributor", "DISTRIBUTOR", loading, distributors!)}
                <div children={"REGION"} />
                <div children={"STATUS"} />
                <div className={"centered"} children={"EDIT"} />
              </div>

              {list &&
                list.map((item: any, i: number) => (
                  <div className={"items"} key={`list-item-${i}`}>
                    <div children={<p>{item?.attributes?.name || "-"}</p>} />
                    <div children={<p>{item?.attributes?.owner?.data?.attributes?.email || "-"}</p>} />
                    <div children={<p>{item?.attributes?.sales_manager?.data?.attributes?.name || "-"}</p>} />
                    <div children={<p>{item?.attributes?.distributor?.data?.attributes?.name || "-"}</p>} />
                    <div children={<p>{item?.attributes?.region?.data?.attributes?.name || "-"}</p>} />
                    <div className={`activity ${getStatus(item)}`} children={getStatus(item)} />
                    <div className={`centered ${!blockPermissions?.edit && "disabled"}`} children={<Link to={!blockPermissions?.edit ? "#" : item?.id} children={<EditDots />} />} />
                  </div>
                ))}

              {!list && (
                <div className={"spinner-wrapper"}>
                  <Spin />
                </div>
              )}

              {list?.length == 0 && (
                <div className={"empty"}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>

            <div
              className={"navigation"}
              children={
                <Pagination
                  showQuickJumper
                  current={page}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                />
              }
            />

            {blockPermissions?.edit && (
              <button onClick={handleCreateNewClick} className={"create-new"}>
                New Salon
                <PlusIcon />
              </button>
            )}

            <button onClick={() => createExportList()} className={`download ${!blockPermissions?.edit && "single"}`} disabled={!list}>
              Download CSV
              {exportLoading ? <Spin /> : <DownloadIcon />}
            </button>

            <CSVLink data={listForExport || []} filename={"salons"} ref={downloadBtnRef as any} />
          </ConfigProvider>
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
}

export default List