import React, { FC } from "react"
import { CloseIcon, SaveIcon } from "../../../../../../../../../icons"
import "./index.less"

const Permissions: FC<RoleManageBlockLeftSidePermissionsInterface> = ({ role }) => {
  let permissions: { [key: string]: string | boolean | null }[] = []
  const fieldTypes = ["_content", "_create", "_edit", "_delete", "_read"]

  const permissionGenerator: PermissionGeneratorFunction = (_content, _create, _edit, _delete, _read) => ({
    _content,
    _create,
    _edit,
    _delete,
    _read,
  })

  const blockCreator = (item: any, i: number) => (
    <div className={"items"} key={`list-item-${item[fieldTypes[i]]}-${i}`}>
      {Array.from({ length: 5 }, (_, i) => (
        <div
          key={`list-item-data-${item[fieldTypes[i]]}-${i}`}
          children={
            <p>
              {item[fieldTypes[i]] == null ? (
                ""
              ) : typeof item[fieldTypes[i]] == "string" ? (
                item[fieldTypes[i]]
              ) : item[fieldTypes[i]] ? (
                <SaveIcon style={{ fill: "#32D74B" }} />
              ) : (
                <CloseIcon style={{ fill: "#F5234B" }} />
              )}
            </p>
          }
        />
      ))}
    </div>
  )

  switch (role) {
    case "super admin":
      permissions = [
        permissionGenerator("Users Management", true, true, true, true),
        permissionGenerator("Post Management", true, true, true, true),
        permissionGenerator("Events Management", true, true, true, true),
        permissionGenerator("Products Management", true, true, true, true),
        permissionGenerator("Notifications Management", true, true, true, true),
        permissionGenerator("Links Management", true, true, true, true),
        permissionGenerator("Dashboard", null, null, null, true),
        permissionGenerator("Roles Management", true, true, true, true),
        permissionGenerator("More Management", true, true, true, true),
      ]
      break
    case "editor":
      permissions = [
        permissionGenerator("Users Management", false, false, false, false),
        permissionGenerator("Post Management", true, true, true, true),
        permissionGenerator("Events Management", true, true, true, true),
        permissionGenerator("Products Management", false, false, false, false),
        permissionGenerator("Notifications Management", true, true, true, true),
        permissionGenerator("Links Management", true, true, true, true),
        permissionGenerator("Dashboard", null, null, null, true),
        permissionGenerator("Roles Management", false, false, false, false),
        permissionGenerator("More Management", false, false, false, false),
      ]
      break
    case "admin":
      permissions = [
        permissionGenerator("Users Management", true, true, true, true),
        permissionGenerator("Post Management", true, true, true, true),
        permissionGenerator("Events Management", true, true, false, true),
        permissionGenerator("Products Management", false, false, false, false),
        permissionGenerator("Notifications Management", true, true, false, true),
        permissionGenerator("Links Management", false, false, false, false),
        permissionGenerator("Dashboard", null, null, null, true),
        permissionGenerator("Roles Management", false, false, false, false),
        permissionGenerator("More Management", false, false, false, false),
      ]
      break
    case "viewer":
      permissions = [
        permissionGenerator("Users Management", false, false, false, false),
        permissionGenerator("Post Management", false, false, false, true),
        permissionGenerator("Events Management", false, false, false, true),
        permissionGenerator("Products Management", false, false, false, false),
        permissionGenerator("Notifications Management", false, false, false, false),
        permissionGenerator("Links Management", false, false, false, false),
        permissionGenerator("Dashboard", null, null, null, true),
        permissionGenerator("Roles Management", false, false, false, false),
        permissionGenerator("More Management", false, false, false, false),
      ]
      break
  }

  return permissions?.length ? (
    <div className={"permissions-block-wrapper"}>
      <div className={"permissions-wrapper"}>
        <div className={"items top"}>
          <div children={"CONTENT"} />
          <div children={"CREATE"} />
          <div children={"EDIT"} />
          <div children={"DELETE"} />
          <div children={"READ"} className={"last"} />
        </div>

        {permissions.map((item: any, i: number) => blockCreator(item, i))}
      </div>
    </div>
  ) : null
}

export default Permissions
